import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";

export default function App() {
  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="rounded-lg flex items-center justify-center flex-col gap-y-5 gap-x-5 p-4 text-white text-2xl">
            <img className="w-12 mb-2" src="/icon-save.svg" alt="" />
            <p>
              Leveraging the latest in AI and machine learning, we provide
              solutions tailored to the unique needs of the construction
              industry.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rounded-lg flex items-center justify-center flex-col gap-y-5 gap-x-5 p-4 text-white text-2xl">
            <img className="w-12 mb-2" src="/icon-hat.svg" alt="" />
            <p>
              From safety monitoring to progress estimation, our platform offers
              an all-in-one solution for construction site management.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rounded-lg flex items-center justify-center flex-col gap-y-5 gap-x-5 p-4 text-white text-2xl">
            <img className="w-12 mb-2" src="/icon-camera.svg" alt="" />
            <p>
              Our system delivers real-time monitoring and detailed analytics,
              enabling proactive decision-making and continuous improvement.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
