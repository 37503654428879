import Container from "./Container";
import Slider from "./Slider";

const Card = () => {
  return (
    <Container>
      <div className="hidden lg:flex wrap relative z-10">
        <div className="card group">
          <div className="front bg-white rounded-lg shadow-md overflow-hidden">
            <img
              src="/safety-efficiency.jpeg"
              alt="Building"
              className="object-cover w-full h-full"
            />
            <div className="absolute inset-0 flex px-4 py-8 text-white justify-end items-baseline flex-col bg-black bg-opacity-50 w-full">
              <img className="w-12 mb-2" src="/icon-save.svg" alt="" />
              <h2 className="text-3xl">Innovative AI Technology</h2>
            </div>
          </div>
          <div className="back bg-teal-500 rounded-lg flex items-center justify-center flex-col gap-y-5 gap-x-5 p-4 text-white text-2xl">
            <img className="w-12 mb-2" src="/icon-save.svg" alt="" />
            <p>
              Leveraging the latest in AI and machine learning, we provide
              solutions tailored to the unique needs of the construction
              industry.
            </p>
          </div>
        </div>
        <div className="card group">
          <div className="front bg-white rounded-lg shadow-md overflow-hidden">
            <img
              src="/comprehensive-solutions.png"
              alt="Building"
              className="object-cover w-full h-full"
            />
            <div className="absolute inset-0 flex px-4 py-8 text-white justify-end items-baseline flex-col bg-black bg-opacity-50 w-full">
              <img className="w-12 mb-2" src="/icon-hat.svg" alt="" />
              <h2 className="text-3xl">Comprehensive Solutions</h2>
            </div>
          </div>
          <div className="back bg-teal-500 rounded-lg flex items-center justify-center flex-col gap-y-5 gap-x-5 p-4 text-white text-2xl">
            <img className="w-12 mb-2" src="/icon-hat.svg" alt="" />
            <p>
              From safety monitoring to progress estimation, our platform offers
              an all-in-one solution for construction site management.
            </p>
          </div>
        </div>
        <div className="card group">
          <div className="front bg-white rounded-lg shadow-md overflow-hidden">
            <img
              src="/realtime-data.png"
              alt="Building"
              className="object-cover w-full h-full"
            />
            <div className="absolute inset-0 flex px-4 py-8 text-white justify-end items-baseline flex-col bg-black bg-opacity-50 w-full">
              <img className="w-12 mb-2" src="/icon-camera.svg" alt="" />
              <h2 className="text-3xl">Real-Time Data & Analytics</h2>
            </div>
          </div>
          <div className="back bg-teal-500 rounded-lg flex items-center justify-center flex-col gap-y-5 gap-x-5 p-4 text-white text-2xl">
            <img className="w-12 mb-2" src="/icon-camera.svg" alt="" />
            <p>
              Our system delivers real-time monitoring and detailed analytics,
              enabling proactive decision-making and continuous improvement.
            </p>
          </div>
        </div>
      </div>

      {/* Telefon ve tablet boyutunda slider */}
      <div className="block lg:hidden">
        <Slider />
      </div>
    </Container>
  );
};

export default Card;
