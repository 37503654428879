"use client";

import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Spline from "@splinetool/react-spline";
import "../App.css";
import LoadingScreen from "../components/LoadingScreen";
import { ArrowRight } from "lucide-react";
import BackgroundAnimation from "../components/BackgroundAnimation";

const ScrollParallaxText = ({ children }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.3], [0, 1]);
  const x = useTransform(scrollYProgress, [0, 0.3], [-100, 0]);

  return (
    <div ref={ref}>
      <motion.p
        style={{ opacity, x }}
        className="mt-4 text-sm sm:text-base w-full leading-[10] relative z-30 text-white"
      >
        {children}
      </motion.p>
    </div>
  );
};

const SplitParagraph = ({ text }) => (
  <div className="relative z-30">
    <ScrollParallaxText>{text}</ScrollParallaxText>
  </div>
);

const BulletPoint = ({ text }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.3], [0, 1]);
  const x = useTransform(scrollYProgress, [0, 0.3], [-50, 0]);

  return (
    <motion.div
      ref={ref}
      style={{ opacity, x }}
      className="flex items-start mt-4"
    >
      <ArrowRight className="text-white mr-2" />
      <p className="text-white text-sm sm:text-base font-medium">{text}</p>
    </motion.div>
  );
};

const SubHeading = ({ text }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.2], [0, 1]);
  const x = useTransform(scrollYProgress, [0, 0.2], [-50, 0]);

  return (
    <motion.h3
      ref={ref}
      style={{ opacity, x }}
      className="text-lg sm:text-xl font-semibold text-[#C8F95B] mt-6"
    >
      {text}
    </motion.h3>
  );
};

const SiteIQ = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div className="App overflow-x-hidden mt-20">
        <BackgroundAnimation />
        <LoadingScreen />
        <Header />
        <div className="container h-full md:h-screen mx-auto flex flex-col items-center justify-center p-4 mt-4">
          <div className="w-full flex flex-col items-center justify-center">
            <h1 className="mt-4 text-2xl sm:text-4xl leading-relaxed font-bold text-[#C8F95B] py-4 relative z-10">
              SiteIQ: Smart Construction Monitoring System
            </h1>
            <p className="text-[#C8F95B] text-base sm:text-xl font-medium relative z-0">
              Revolutionizing construction site management with real-time data
              and intelligent monitoring.
            </p>
          </div>

          <div className="relative z-50 flex items-center justify-end h-full w-full mr-[-80px] hidden md:flex">
            <Spline scene="https://prod.spline.design/LSaScc3pirBH4dev/scene.splinecode" />
          </div>
          <div className="block md:hidden h-[390px]">
            <Spline scene="https://prod.spline.design/WGrIAKiU1cApxZzv/scene.splinecode" />
          </div>
        </div>
      </div>

      {/* Overview Section */}
      <div className="container relative mx-auto flex flex-col md:flex-row items-start justify-between px-12 mb-10">
        <div className="w-full">
          <ScrollParallaxText>
            <h2 className="text-xl sm:text-2xl font-bold text-[#C8F95B] mt-8">
              Overview
            </h2>
          </ScrollParallaxText>
          <div className="relative z-30">
            <ScrollParallaxText>
              <p className="leading-normal sm:leading-[45px]">
                SiteIQ is an AI-powered platform that transforms construction
                site management by offering real-time insights, task automation,
                and advanced data analytics. With comprehensive monitoring
                tools, SiteIQ ensures safety, compliance, and efficiency, making
                it an essential solution for modern construction projects.
              </p>
            </ScrollParallaxText>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="container relative mx-auto flex flex-col md:flex-row items-start justify-between px-12 mb-10">
        <div className="w-full">
          <ScrollParallaxText>
            <h2 className="text-xl sm:text-2xl font-bold text-[#C8F95B] mt-8">
              Features
            </h2>
          </ScrollParallaxText>

          <div className="pl-6">
            <SubHeading text="Task Management System" />
            <BulletPoint text="Centralized task assignment, tracking, and completion in real-time." />
            <BulletPoint text="Customizable dashboards for quick access to ongoing tasks and their statuses." />

            <SubHeading text="GPS-Based Worker Tracking" />
            <BulletPoint text="Real-time location tracking of all on-site personnel for improved safety and compliance." />
            <BulletPoint text="Immediate alerts for unauthorized access to restricted areas, reducing risks and ensuring workplace security." />

            <SubHeading text="Concrete Quality Monitoring" />
            <BulletPoint text="Seamless integration with concrete sensors that monitor strength and temperature during the curing process." />
            <BulletPoint text="Automatic alerts if temperatures or strength readings go outside safe thresholds, ensuring quality control." />

            <SubHeading text="AI-Powered Rebar Placement Verification" />
            <BulletPoint text="AI-driven verification of rebar placement, comparing real-time images against structural blueprints." />
            <BulletPoint text="Identifies placement errors early, reducing rework costs and improving structural integrity." />

            <SubHeading text="Comprehensive Analytics Dashboard" />
            <BulletPoint text="Data-driven insights and customizable visualizations, allowing managers to monitor task progress, worker safety, and resource allocation." />
            <BulletPoint text="Daily, weekly, and monthly reports that provide a clear picture of project status and performance." />
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="container relative mx-auto flex flex-col md:flex-row items-start justify-between px-12 mb-10">
        <div className="w-full">
          <ScrollParallaxText>
            <h2 className="text-xl sm:text-2xl font-bold text-[#C8F95B] mt-2">
              Benefits
            </h2>
          </ScrollParallaxText>
          <div className="pl-6">
            <BulletPoint text="Enhanced Safety: Improved compliance with safety standards and reduced accident risks through real-time monitoring." />
            <BulletPoint text="Operational Efficiency: Automated task tracking and quality control save time and reduce manual errors." />
            <BulletPoint text="Cost Savings: Early issue detection in concrete curing and rebar placement minimizes rework and maximizes resource use." />
            <BulletPoint text="Data-Driven Decisions: Comprehensive analytics support better planning and faster, more informed decision-making." />
          </div>
        </div>
      </div>

      {/* Get Started Section */}
      <div className="container relative mx-auto flex flex-col px-12 mb-32 sm:mb-10 z-20">
        <ScrollParallaxText>
          <h2 className="text-xl sm:text-2xl font-bold text-[#C8F95B] mt-8">
            Get Started
          </h2>
        </ScrollParallaxText>
        <ScrollParallaxText>
          <p className="leading-normal sm:leading-[45px]">
            Bring your construction site into the future with SiteIQ.{" "}
            <Link
              to="/contact"
              onClick={scrollToTop}
              className="font-bold underline text-white"
            >
              Contact us {" "}
            </Link>
             to learn more about how SiteIQ can transform your operations.
          </p>
        </ScrollParallaxText>
      </div>

      <Footer />
    </>
  );
};

export default SiteIQ;
