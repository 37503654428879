"use client";

import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useForm, ValidationError } from "@formspree/react";
import "../App.css";
import LoadingScreen from "../components/LoadingScreen";
import Container from "../components/Container";
import BackgroundAnimation from "../components/BackgroundAnimation";

const questions = [
  {
    id: 1,
    title: "What inspired the creation of VisionBuild?",
    description:
      "The idea originated from the need to enhance safety and efficiency on construction sites. Observing frequent safety breaches and inefficiencies, our founders decided to leverage AI technology to provide real-time monitoring and proactive solutions. The specific needs of our partner, Sayram Construction, significantly inspired the development of our project.",
  },
  {
    id: 2,
    title:
      "What are the benefits of using VisionBuild for construction site management?",
    description:
      "VisionBuild enhances construction site safety, improves compliance with safety regulations, and provides real-time monitoring and alerts. This leads to reduced accidents, improved efficiency, and more effective resource management.",
  },
  {
    id: 3,
    title: "What industries can benefit from VisionBuild?",
    description:
      "While VisionBuild is primarily designed for the construction industry, it can also be beneficial for other industries that require real-time monitoring and safety compliance, such as manufacturing, logistics, and warehousing.",
  },
  {
    id: 4,
    title: "Is there a trial period available for VisionBuild?",
    description:
      "Yes, we offer a trial period for new clients to experience the benefits of VisionBuild firsthand. Please contact us for more details on how to get started with a trial.",
  },
  {
    id: 5,
    title: "Where do you store the data?",
    description:
      "Our data is stored on secure cloud servers. We prioritize data privacy and security, employing the latest encryption technologies to protect your information.",
  },
  {
    id: 6,
    title: "What kind of support do you offer during and after implementation?",
    description:
      "We provide extensive support during the implementation phase, including training and setup assistance. After implementation, we offer ongoing support and updates to ensure our system continues to meet your needs.",
  },
  {
    id: 7,
    title:
      "Do we need to add any hardware for this, or do we need to buy your cameras?",
    description:
      "You can use your existing cameras with our system. There is no need to purchase additional hardware.",
  },
  {
    id: 8,
    title:
      "Do the requirements for your system differ from country to country within Europe?",
    description:
      "While the core functionality of our system remains the same, the requirements can vary depending on local regulations and standards. We customize our system to meet the specific regulations and standards of each European country.",
  },
  {
    id: 9,
    title:
      "Can your system be integrated with existing project management tools?",
    description:
      "Yes, our system is designed to integrate seamlessly with a variety of existing project management tools to provide a comprehensive solution for construction site management.",
  },
  {
    id: 10,
    title: "How do you ensure data privacy and security?",
    description:
      "We employ the latest encryption technologies and adhere to strict data privacy policies to ensure that all information is securely stored and protected.",
  },
  {
    id: 11,
    title: "How does VisionBuild differentiate itself from competitors?",
    description:
      "Our unique value proposition lies in our comprehensive approach that combines safety gear detection, restricted area monitoring, and real-time alerts in one integrated system. Our focus on using existing infrastructure makes implementation seamless and cost-effective.",
  },
  {
    id: 12,
    title:
      "Do you offer any unique features that are exclusive to VisionBuild?",
    description:
      "Yes, our real-time, AI-driven safety and compliance monitoring system utilizes existing security cameras without the need for additional hardware investments. This provides a cost-effective, scalable solution that is easy to integrate into current workflows.",
  },
  {
    id: 13,
    title: "What is the pricing model for VisionBuild?",
    description:
      "Our pricing model is based on the specific needs of your project, including the size of the site and the number of features required. Please contact us for a detailed quote tailored to your needs.",
  },
  {
    id: 14,
    title: "How can I request a demo?",
    description:
      "You can request a demo by contacting us through our website or reaching out directly via email or phone. We will arrange a convenient time to demonstrate our system’s capabilities and answer any questions you may have.",
  },
];

const FrequentlyAskedQuestions = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="App overflow-x-hidden">
      <LoadingScreen />
      <Header />
      <BackgroundAnimation />
      <Container>
        <section className="py-24 relative z-10 flex flex-col md:flex-row items-center justify-between mt-20">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row">
            <div className="w-full">
              <div className="accordion-group">
                {questions.map((question, index) => (
                  <div
                    key={question.id}
                    className={`accordion border border-solid p-4 rounded-xl mb-8 lg:p-4 transition duration-500 relative z-50 ${
                      activeIndex === index
                        ? " bg-opacity-80 backdrop-blur-sm border-[#7660F9]"
                        : "border-[#C8F95B]"
                    }`}
                  >
                    <button
                      className="accordion-toggle group inline-flex items-center justify-between text-left text-lg font-normal leading-8 text-white w-full transition duration-500 hover:text-indigo-600"
                      onClick={() => handleAccordionClick(index)}
                    >
                      <h5>{question.title}</h5>
                      <svg
                        className={`w-6 h-6 transition-transform duration-500 ${
                          activeIndex === index ? "rotate-0" : "rotate-180"
                        }`}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 12H18"
                          stroke="currentColor"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        {activeIndex !== index && (
                          <path
                            d="M12 6V18"
                            stroke="currentColor"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        )}
                      </svg>
                    </button>
                    <div
                      className={`accordion-content overflow-hidden transition-max-height duration-500 ease-in-out ${
                        activeIndex === index ? "max-h-40" : "max-h-0"
                      }`}
                    >
                      <p className="text-base text-white font-normal leading-6 mt-4">
                        {question.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </Container>
      <Footer />
    </div>
  );
};

export default FrequentlyAskedQuestions;
