import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import Header from "../components/Header";
import Container from "../components/Container";
import Footer from "../components/Footer";
import LoadingScreen from "../components/LoadingScreen";
import BackgroundAnimation from "../components/BackgroundAnimation";

function ContactForm() {
  const [state, handleSubmit] = useForm("mpwzzzzb");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    country: "",
    industry: "",
    job: "",
    message: "", 
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      country: "",
      industry: "",
      job: "",
      message: "", 
    });
  };

  const handleFormSubmit = async (e) => {
    await handleSubmit(e);
    if (state.succeeded) {
      clearForm(); 
    }
  };

  return (
    <div>
      <Header />
      <BackgroundAnimation />
      <div className="mt-[150px] mb-16 relative">
        <LoadingScreen />
        <Container>
          <h1 className="text-xl sm:text-3xl text-[#C8F95B] text-center font-bold">
            Experience the Future of Construction Safety and Site Management
          </h1>
          <p className="px-6 sm:px-16 py-4 font-medium leading-relaxed">
            Discover how our innovative solutions can transform your
            construction site operations. From real-time safety monitoring to
            advanced project tracking, our platforms provide the tools you need
            for a safer, more efficient, and data-driven worksite. Request a
            live demo to explore our technology in action, ask questions, and
            see how we can support your unique project needs.
          </p>
          <h4 className="mt-6 text-base sm:text-xl text-[#C8F95B] text-center font-bold">
            Get Started with a Personalized Demo
          </h4>
          <p className="px-6 sm:px-16 py-4 font-medium leading-relaxed">
            Fill out the form below, and a member of our team will be in touch
            to schedule your demo. Let’s work together to elevate your site
            safety and project performance!
          </p>
          <div className="flex flex-col gap-y-5 px-4 sm:px-16 mt-4">
            <p className="ml-9">Contact us to learn:</p>
            <div className="flex items-center">
              <img
                className="w-[30px] h-[30px] mr-2"
                src="/camera.svg"
                alt=""
              />
              How our AI solutions elevate site safety and efficiency.
            </div>
            <div className="flex items-center">
              <img
                className="w-[30px] h-[30px] mr-2"
                src="/camera.svg"
                alt=""
              />
              How SiteIQ and SafetyAI enhance your current operations.
            </div>
            <div className="flex items-center">
              <img
                className="w-[30px] h-[30px] mr-2"
                src="/camera.svg"
                alt=""
              />
              How real-time insights drive proactive decision-making.
            </div>
          </div>
          <div className="flex flex-col items-center gap-y-4 px-2 sm:px-16 mt-10 relative z-20">
            <p>
              Share your contact details to request a demonstration, and we’ll
              be in touch soon!
            </p>
            <div className="border-solid border border-[#C8F95B] w-full max-w-4xl rounded-xl">
              <form
                onSubmit={handleFormSubmit}
                className="p-6 shadow-md space-y-6"
              >
                <div className="flex justify-between items-center gap-x-4">
                  <div className="w-full">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-white mb-1"
                    >
                      First Name
                    </label>
                    <input
                      id="first-name"
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-4 py-2 border border-[#7660F9] rounded-md shadow-sm bg-[#3c416a] focus:bg-[#7660F9] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <ValidationError
                      prefix="First Name"
                      field="firstName"
                      errors={state.errors}
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-white mb-1"
                    >
                      Last Name
                    </label>
                    <input
                      id="last-name"
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-4 py-2 border border-[#7660F9] rounded-md shadow-sm bg-[#3c416a] focus:bg-[#7660F9] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <ValidationError
                      prefix="Last Name"
                      field="lastName"
                      errors={state.errors}
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                </div>
                {/* Email Address */}
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-white mb-1"
                  >
                    Email Address
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-4 py-2 border border-[#7660F9] rounded-md shadow-sm bg-[#3c416a] focus:bg-[#7660F9] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-white mb-1"
                  >
                    Phone
                  </label>
                  <input
                    id="phone"
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-4 py-2 border border-[#7660F9] rounded-md shadow-sm bg-[#3c416a] focus:bg-[#7660F9] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <ValidationError
                    prefix="Phone"
                    field="phone"
                    errors={state.errors}
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* Company */}
                <div>
                  <label
                    htmlFor="company"
                    className="block text-sm font-medium text-white mb-1"
                  >
                    Company
                  </label>
                  <input
                    id="company"
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-4 py-2 border border-[#7660F9] rounded-md shadow-sm bg-[#3c416a] focus:bg-[#7660F9] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <ValidationError
                    prefix="Company"
                    field="company"
                    errors={state.errors}
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-white mb-1"
                  >
                    Country
                  </label>
                  <input
                    id="country"
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-4 py-2 border border-[#7660F9] rounded-md shadow-sm bg-[#3c416a] focus:bg-[#7660F9] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <ValidationError
                    prefix="Country"
                    field="country"
                    errors={state.errors}
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* Industry */}
                <div>
                  <label
                    htmlFor="industry"
                    className="block text-sm font-medium text-white mb-1"
                  >
                    Industry
                  </label>
                  <input
                    id="industry"
                    type="text"
                    name="industry"
                    value={formData.industry}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-4 py-2 border border-[#7660F9] rounded-md shadow-sm bg-[#3c416a] focus:bg-[#7660F9] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <ValidationError
                    prefix="Industry"
                    field="industry"
                    errors={state.errors}
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* Job */}
                <div>
                  <label
                    htmlFor="job"
                    className="block text-sm font-medium text-white mb-1"
                  >
                    Job Role
                  </label>
                  <input
                    id="job"
                    type="text"
                    name="job"
                    value={formData.job}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-4 py-2 border border-[#7660F9] rounded-md shadow-sm bg-[#3c416a] focus:bg-[#7660F9] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <ValidationError
                    prefix="Job"
                    field="job"
                    errors={state.errors}
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* Message (Textarea) */}
                <div>
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-white mb-1"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    rows="4"
                    className="mt-1 block w-full px-4 py-2 border border-[#7660F9] rounded-md shadow-sm bg-[#3c416a] focus:bg-[#7660F9] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  disabled={state.submitting}
                  className="w-full cursor-pointer inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
                >
                  Submit
                </button>

                {state.succeeded && (
                  <p className="text-green-500 font-semibold mt-4">
                    Form successfully submitted! Thank you!
                  </p>
                )}
              </form>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

function App() {
  return <ContactForm />;
}

export default App;
