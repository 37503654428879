import "./App.css";
import Header from "./components/Header";
import Banner from "./components/Banner";
import Card from "./components/Card";
import Question from "./components/Question";
import { AskedQuestion } from "./components/AskedQuestion";
import Partners from "./components/Partners";
import Footer from "./components/Footer";
import LoadingScreen from "./components/LoadingScreen";
import BackgroundAnimation from './components/./BackgroundAnimation';



function App() {
  return (
    <div className="App overflow-x-hidden">
      <BackgroundAnimation />
      <LoadingScreen />
      <Header />
      <Banner />
      <Card />
      <Question />
      <AskedQuestion />
      <Partners />
      <Footer />
    </div>
  );
}

export default App;
