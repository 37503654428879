import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter as Router, Routes, Route } from "react-router-dom"; 
import "./index.css";
import App from "./App";
import About from "./pages/About";
import SafetyMonitoring from "./pages/SafetyMonitoring"; 
import SiteIQ from "./pages/SiteIQ"; 
import reportWebVitals from "./reportWebVitals";
import ContactForm from "./pages/ContactForm";
import Contact from "./pages/Contact";
import FrequentlyAskedQuestions from "./pages/FrequentlyAskedQuestions";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about" element={<About />} />
        <Route path="/safetymonitoring" element={<SafetyMonitoring />} />
        <Route path="/siteiq" element={<SiteIQ />} />
        <Route path="/contactform" element={<ContactForm />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/frequentlyaskedquestions" element={<FrequentlyAskedQuestions />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
