import React, { useEffect, useRef } from "react";
import gsap from "gsap";

const BlurryCursor = ({ isActive }) => {
  const circle = useRef(null);

  // Fare hareketini işleyen fonksiyon
  const manageMouseMove = (e) => {
    const { clientX, clientY } = e;
    gsap.to(circle.current, {
      x: clientX,
      y: clientY,
      xPercent: -50,
      yPercent: -50,
      ease: "power3.out",
      duration: 0.1,
    });
  };

  // Daireyi büyütme/küçültme fonksiyonu
  useEffect(() => {
    if (isActive) {
      gsap.to(circle.current, {
        scale: 10, // Daha büyük ölçek
        duration: 0.3,
        ease: "power3.out",
      });
    } else {
      gsap.to(circle.current, {
        scale: 1, // Küçük ölçek
        duration: 0.3,
        ease: "power3.out",
      });
    }
  }, [isActive]);

  // Fare hareketini izleyen event listener
  useEffect(() => {
    window.addEventListener("mousemove", manageMouseMove);
    return () => {
      window.removeEventListener("mousemove", manageMouseMove);
    };
  }, []);

  return (
    <div>
      <div
        ref={circle}
        style={{
          backgroundColor: "#C8F95B",
          width: 30,
          height: 30,
          borderRadius: "50%",
          position: "fixed",
          top: 0,
          left: 0,
          pointerEvents: "none",
          mixBlendMode: "difference",
          zIndex: 9999,
          transform: "scale(1)",
        }}
        className="circle hidden md:block" // Mobil ve tabletlerde gizlenir, büyük ekranlarda görünür
      />
    </div>
  );
};

export default BlurryCursor;
