import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Roadmap = () => {
  useEffect(() => {
    const svgHeight = document.getElementById("svg-stage").clientHeight;

    gsap.fromTo(
      ".theLine",
      { strokeDasharray: "1000", strokeDashoffset: "1000" },
      {
        strokeDashoffset: 0,
        scrollTrigger: {
          trigger: ".theLine",
          start: "top center",
          end: `+=${svgHeight}`,
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      ".ball",
      { scale: 0, visibility: "hidden" },
      {
        scale: 1,
        visibility: "visible",
        scrollTrigger: {
          trigger: ".theLine",
          start: "top center",
          end: `+=${svgHeight}`,
          scrub: true,
        },
      }
    );

    gsap.to(".text01, .text02, .text03", {
      scrollTrigger: {
        trigger: "#svg-stage",
        start: "top center",
        end: `+=${svgHeight}`,
        scrub: true,
      },
      visibility: "visible",
      opacity: 1,
      stagger: 0.2,
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <svg
        id="svg-stage"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 600 800"
        className="w-full max-w-[600px] max-h-[550px]"
      >
        <text className="text02" x="60" y="180">
          January 2025
        </text>
        <text className="text03" x="150" y="310">
          April 2025
        </text>
        <text className="text03" x="75" y="445">
          September 2025
        </text>
        <text className="text03" x="40" y="550">
          January 2026
        </text>

        <path
          className="theLine"
          d="M -5,0
             Q 450 230 300 450 
             T 130 650"
          fill="none"
          stroke="white"
          strokeWidth="10px"
        />

        <circle className="ball ball03" r="20" cx="250" cy="170"></circle>
        <circle className="ball ball04" r="20" cx="338" cy="301"></circle>
        <circle className="ball ball04" r="20" cx="303" cy="440"></circle>
        <circle className="ball ball04" r="20" cx="233" cy="550"></circle>
      </svg>
    </div>
  );
};

export default Roadmap;
