import React, { useState } from "react";
import Container from "./Container";

const questions = [
  {
    id: 1,
    title: "What inspired the creation of VisionBuild?",
    description:
      "The idea originated from the need to enhance safety and efficiency on construction sites. Observing frequent safety breaches and inefficiencies, our founders decided to leverage AI technology to provide real-time monitoring and proactive solutions. The specific needs of our partner, Sayram Construction, significantly inspired the development of our project.",
  },
  {
    id: 2,
    title: "What are the benefits of using VisionBuild for construction site management?",
    description:
      "VisionBuild enhances construction site safety, improves compliance with safety regulations, and provides real-time monitoring and alerts. This leads to reduced accidents, improved efficiency, and more effective resource management.",
  },
  {
    id: 3,
    title: "What industries can benefit from VisionBuild?",
    description:
      "While VisionBuild is primarily designed for the construction industry, it can also be beneficial for other industries that require real-time monitoring and safety compliance, such as manufacturing, logistics, and warehousing.",
  },
  {
    id: 4,
    title: "Is there a trial period available for VisionBuild?",
    description:
      "Yes, we offer a trial period for new clients to experience the benefits of VisionBuild firsthand. Please contact us for more details on how to get started with a trial.",
  },
  {
    id: 5,
    title: "Do we need to add any hardware for this, or do we need to buy your cameras? ",
    description:
      "You can use your existing cameras with our system. There is no need to purchase additional hardware.",
  },
  {
    id: 6,
    title: "Can your system be integrated with existing project management tools? ",
    description:
      "Yes, our system is designed to integrate seamlessly with a variety of existing project management tools to provide a comprehensive solution for construction site management.",
  },
  {
    id: 7,
    title: "What is the pricing model for VisionBuild? ",
    description:
      "Our pricing model is based on the specific needs of your project, including the size of the site and the number of features required. Please contact us for a detailed quote tailored to your needs.",
  },
];

function AnimatedMeshGradient() {
  return (
    <div className="absoluteAnimation inset-0 justify-center z-10">
      <div className="bg-shape2 bg-[#C8F95B] opacity-50 bg-blur z-10"></div>
      <div className="bg-shape2 bg-[#C8F95B] opacity-50 bg-blur z-10"></div>
    </div>
  );
}

export const AskedQuestion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <Container>
      <section className="py-24 relative z-50 flex flex-col md:flex-row items-center justify-between">
        <AnimatedMeshGradient />
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row">
          <div className="w-full md:w-1/4 mb-8 md:mb-0">
            <img src="/faq.png" alt="FAQ" className="w-[80] h-auto" />
          </div>
          <div className="w-full md:w-3/4">
            <div className="accordion-group">
              {questions.map((question, index) => (
                <div
                  key={question.id}
                  className={`accordion border border-solid p-4 rounded-xl mb-8 lg:p-4 transition duration-500 relative z-50 ${
                    activeIndex === index
                      ? " bg-opacity-80 backdrop-blur-sm border-[#7660F9]"
                      : "border-[#C8F95B]"
                  }`}
                >
                  <button
                    className="accordion-toggle group inline-flex items-center justify-between text-left text-lg font-normal leading-8 text-white w-full transition duration-500 hover:text-indigo-600"
                    onClick={() => handleAccordionClick(index)}
                  >
                    <h5>{question.title}</h5>
                    <svg
                      className={`w-6 h-6 transition-transform duration-500 ${
                        activeIndex === index ? "rotate-0" : "rotate-180"
                      }`}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 12H18"
                        stroke="currentColor"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      {activeIndex !== index && (
                        <path
                          d="M12 6V18"
                          stroke="currentColor"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      )}
                    </svg>
                  </button>
                  <div
                    className={`accordion-content overflow-hidden transition-max-height duration-500 ease-in-out ${
                      activeIndex === index ? "max-h-40" : "max-h-0"
                    }`}
                  >
                    <p className="text-base text-white font-normal leading-6 mt-4">
                      {question.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default AskedQuestion;
