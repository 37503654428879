"use client";

import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Roadmap from "../components/RoadMap";
import BlurryCursor from "../components/BlurryCursor";
import Spline from "@splinetool/react-spline";
import { useForm, ValidationError } from "@formspree/react";
import OurTeam from "../components/OurTeam";
import "../App.css";
import LoadingScreen from "../components/LoadingScreen";
import Container from "../components/Container";
import BackgroundAnimation from "../components/BackgroundAnimation";

const Contact = () => {
  const [isActive, setIsActive] = useState(false);

  const [state, handleSubmit] = useForm("mdkoooeo");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    country: "",
    industry: "",
    job: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      country: "",
      industry: "",
      job: "",
      message: "",
    });
  };

  const handleFormSubmit = async (e) => {
    await handleSubmit(e);
    if (state.succeeded) {
      clearForm();
    }
  };

  return (
    <div className="App overflow-x-hidden">
      <LoadingScreen />
      <BackgroundAnimation />
      <Header />
      <div className="mx-auto flex flex-col md:flex-row items-center justify-center sm:justify-between z-20 relative h-screen">
        <div className="w-full sm:w-3/5 mb-4">
          <Spline
            className="contact-spline"
            scene="https://prod.spline.design/r3swUYQ-iGiwz3po/scene.splinecode"
          />
        </div>
        <div className="w-full sm:w-2/5">
          <Container>
            <div className="flex flex-col gap-y-6 sm:gap-y-16">
              <div className="location-box flex items-center">
                <img className="w-[40px]" src="/icon-location.svg" alt="" />
                <a className="ml-4">Singel 542, 1017AZ Amsterdam/Netherlands</a>
              </div>
              <div className="location-box flex items-center">
                <img className="w-[40px]" src="/icon-email.svg" alt="" />
                <a className="ml-4">info@visionbuild.ai</a>
              </div>
              <div className="location-box flex items-center">
                <img className="w-[40px]" src="/icon-phone.svg" alt="" />
                <a className="ml-4">+31612208696</a>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between z-20 relative p-8">
        <img
          className="w-full h-[500px] object-cover rounded-md"
          src="/location-map.png"
          alt=""
        />
      </div>
      <Container>
        <div className="flex flex-col items-center gap-y-4 px-2 sm:px-16 mt-10 relative z-20">
          <h1 className="text-[#C8F95B] uppercase font-bold text-lg md:text-[34px]">
            Have questions? Get in touch with VisionBuild!
          </h1>
          <div className="border-solid border border-[#C8F95B] w-full max-w-4xl rounded-xl mb-10">
            <form
              onSubmit={handleFormSubmit}
              className="p-6 shadow-md space-y-6"
            >
              <div className="flex justify-between items-center gap-x-4">
                <div className="w-full">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-white mb-1"
                  >
                    First Name
                  </label>
                  <input
                    id="first-name"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-4 py-2 border border-[#7660F9] rounded-md shadow-sm bg-[#3c416a] focus:bg-[#7660F9] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <ValidationError
                    prefix="First Name"
                    field="firstName"
                    errors={state.errors}
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-white mb-1"
                  >
                    Last Name
                  </label>
                  <input
                    id="last-name"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-4 py-2 border border-[#7660F9] rounded-md shadow-sm bg-[#3c416a] focus:bg-[#7660F9] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <ValidationError
                    prefix="Last Name"
                    field="lastName"
                    errors={state.errors}
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-white mb-1"
                >
                  Email Address
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-4 py-2 border border-[#7660F9] rounded-md shadow-sm bg-[#3c416a] focus:bg-[#7660F9] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-white mb-1"
                >
                  Phone
                </label>
                <input
                  id="phone"
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-4 py-2 border border-[#7660F9] rounded-md shadow-sm bg-[#3c416a] focus:bg-[#7660F9] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <ValidationError
                  prefix="Phone"
                  field="phone"
                  errors={state.errors}
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              {/* Company */}
              <div>
                <label
                  htmlFor="company"
                  className="block text-sm font-medium text-white mb-1"
                >
                  Company
                </label>
                <input
                  id="company"
                  type="text"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-4 py-2 border border-[#7660F9] rounded-md shadow-sm bg-[#3c416a] focus:bg-[#7660F9] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <ValidationError
                  prefix="Company"
                  field="company"
                  errors={state.errors}
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-white mb-1"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  rows="4"
                  className="mt-1 block w-full px-4 py-2 border border-[#7660F9] rounded-md shadow-sm bg-[#3c416a] focus:bg-[#7660F9] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={state.submitting}
                className="w-full cursor-pointer inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                Submit
              </button>

              {state.succeeded && (
                <p className="text-green-500 font-semibold mt-4">
                  Form successfully submitted! Thank you!
                </p>
              )}
            </form>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Contact;
