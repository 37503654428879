import React, { useEffect } from 'react';
import { gsap } from 'gsap';

// Mor ve yeşil tonları eşit şekilde görünmesi için renkler çoğaltıldı
const colors = ["#C8F95B", "#C8F95B", "#7660F9", "#7660F9"]; // 2 yeşil, 2 mor

function createBubble() {
  const bubble = document.createElement("div");
  bubble.classList.add("bubble");

  const size = Math.random() * 200 + 300;
  bubble.style.width = `${size}px`;
  bubble.style.height = `${size}px`;
  bubble.style.backgroundColor = colors[Math.floor(Math.random() * colors.length)];

  bubble.style.left = `${Math.random() * 60 + 20}vw`;
  bubble.style.top = `${Math.random() * 150}vh`;

  document.body.appendChild(bubble);

  gsap.to(bubble, {
    x: "random(-100px, 100px)", // `vw` yerine `px` kullanarak kaydırma sorununu azaltıyoruz
    y: "random(-100px, 100px)",
    scale: "random(0.85, 1.05)",
    duration: "random(12, 20)",
    opacity: 0.15,
    ease: "power1.inOut",
    repeat: -1,
    yoyo: true,
    rotation: "random(0, 360)"
  });
}

const BackgroundAnimation = () => {
  useEffect(() => {
    // Önceki baloncukları temizle
    const existingBubbles = document.querySelectorAll(".bubble");
    existingBubbles.forEach(bubble => bubble.remove());

    // Baloncukları oluştur
    for (let i = 0; i < 10; i++) {
      createBubble();
    }

    // Arka plan animasyonunu ayarla
    const b1 = "linear-gradient(217deg, rgba(118, 96, 249, .2), rgba(118, 96, 249, 0) 80%), linear-gradient(127deg, rgba(200, 249, 91, .15), rgba(200, 249, 91, 0) 80%), linear-gradient(336deg, rgba(118, 96, 249, .2), rgba(118, 96, 249, 0) 80%)";
    const b2 = "linear-gradient(17deg, rgba(118, 96, 249, .15), rgba(118, 96, 249, 0) 80%), linear-gradient(200deg, rgba(200, 249, 91, .1), rgba(200, 249, 91, .05) 80%), linear-gradient(336deg, rgba(118, 96, 249, .15), rgba(118, 96, 249, 0.05) 80%)";

    const bgAnimation = gsap.fromTo("#a", { background: b1 }, { background: b2, duration: 6, ease: "none", repeat: -1, yoyo: true });

    return () => {
      document.querySelectorAll(".bubble").forEach(bubble => bubble.remove());
      bgAnimation.kill();
    };
  }, []);

  return <div id="a" style={styles.container}></div>;
};

// `BackgroundAnimation` bileşeni hafif şeffaf yapıldı
const styles = {
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
    fontSize: "4rem",
    fontWeight: "bold",
    color: "#C8F95B",
    textAlign: "left",
    backgroundColor: "rgba(19, 19, 19, 0.8)", // Yarı şeffaf koyu arka plan
    filter: "blur(10px)",
    pointerEvents: "none", // Etkileşim engellenir
  },
};

export default BackgroundAnimation;
