"use client";

import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Spline from "@splinetool/react-spline";
import LoadingScreen from "../components/LoadingScreen";
import { ArrowRight } from "lucide-react";
import BackgroundAnimation from "../components/BackgroundAnimation";

const ScrollParallaxText = ({ children }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.3], [0, 1]);
  const x = useTransform(scrollYProgress, [0, 0.3], [-100, 0]);

  return (
    <div ref={ref}>
      <motion.p
        style={{ opacity, x }}
        className="mt-4 text-sm sm:text-base w-full leading-[10] relative z-30 text-white"
      >
        {children}
      </motion.p>
    </div>
  );
};

const SplitParagraph = ({ text }) => (
  <div className="relative z-30">
    <ScrollParallaxText>{text}</ScrollParallaxText>
  </div>
);

const BulletPoint = ({ text }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.3], [0, 1]);
  const x = useTransform(scrollYProgress, [0, 0.3], [-50, 0]);

  return (
    <motion.div
      ref={ref}
      style={{ opacity, x }}
      className="flex items-start mt-4"
    >
      <ArrowRight className="text-white mr-2" />
      <p className="text-white text-sm sm:text-base font-medium">{text}</p>
    </motion.div>
  );
};

const SubHeading = ({ text }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.2], [0, 1]);
  const x = useTransform(scrollYProgress, [0, 0.2], [-50, 0]);

  return (
    <motion.h3
      ref={ref}
      style={{ opacity, x }}
      className="text-lg sm:text-xl font-semibold text-[#C8F95B] mt-6"
    >
      {text}
    </motion.h3>
  );
};

const SafetyMonitoring = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div className="App overflow-x-hidden mt-20">
        <LoadingScreen />
        <BackgroundAnimation />
        <Header />
        <div className=" mx-auto flex flex-col items-center justify-center mt-2">
          <div className="w-full flex flex-col items-center justify-center p-4">
            <h1 className="mt-4 text-2xl sm:text-4xl leading-relaxed font-bold text-[#C8F95B] py-4 relative z-10">
              Safety AI: Real-Time Construction Safety Compliance
            </h1>
            <p className="text-[#C8F95B] text-base sm:text-xl font-medium relative z-0">
              Intelligent monitoring for safer, more compliant construction
              sites.
            </p>
          </div>
          <div className="relative z-10 pointer-events-none h-[750px] w-full justify-start items-start hidden md:flex">
            <Spline scene="https://prod.spline.design/YjRxYP4vSqpMu6bE/scene.splinecode" />
          </div>

          <div className="block md:hidden h-[420px] pointer-events-none mt-12 w-full relative z-10 mr[-20px]">
            <Spline scene="https://prod.spline.design/l0WiZSJ7Ikyv7eMT/scene.splinecode" />
          </div>
        </div>
      </div>
      {/* Overview Section */}
      <div className="container relative mx-auto flex flex-col md:flex-row items-start justify-between px-12 mb-10">
        <div className="w-full">
          <ScrollParallaxText>
            <h2 className="text-xl sm:text-2xl font-bold text-[#C8F95B] mt-8">
              Overview
            </h2>
          </ScrollParallaxText>
          <div className="relative z-30">
            <ScrollParallaxText>
              <p className="leading-normal sm:leading-[45px]">
                Safety AI is a powerful AI-driven platform designed to ensure
                safety compliance on construction sites. Leveraging existing
                security cameras and advanced algorithms, Safety AI continuously
                monitors workers to verify they are wearing the necessary safety
                gear and adhering to site protocols. It’s an essential tool for
                any site prioritizing safety and regulatory compliance.
              </p>
            </ScrollParallaxText>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="container relative mx-auto flex flex-col md:flex-row items-start justify-between px-12 mb-10">
        <div className="w-full">
          <ScrollParallaxText>
            <h2 className="text-xl sm:text-2xl font-bold text-[#C8F95B] mt-8">
              Features
            </h2>
          </ScrollParallaxText>

          <div className="pl-6">
            <SubHeading text="Safety Gear Detection" />
            <BulletPoint text="Continuous monitoring of safety gear compliance, such as helmets, vests, gloves, and other required PPE." />
            <BulletPoint text="Real-time alerts are sent to site supervisors if a breach in safety gear is detected, helping prevent accidents." />

            <SubHeading text="Restricted Area Monitoring" />
            <BulletPoint text="AI-powered monitoring of restricted zones to prevent unauthorized access." />
            <BulletPoint text="Instant notifications to site managers in case of unauthorized entry, improving site security and compliance." />

            <SubHeading text="Incident Reporting and Alerts" />
            <BulletPoint text="Instant alerts and incident reports sent to supervisors through a centralized platform or mobile app." />
            <BulletPoint text="Comprehensive incident logs provide insights for safety audits and proactive risk management." />

            <SubHeading text="Compliance Analytics" />
            <BulletPoint text="Detailed analytics for tracking safety compliance rates and identifying areas of improvement." />
            <BulletPoint text="Exportable reports for audits, regulatory reviews, and ongoing safety assessments." />
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="container relative mx-auto flex flex-col md:flex-row items-start justify-between px-12 mb-10">
        <div className="w-full">
          <ScrollParallaxText>
            <h2 className="text-xl sm:text-2xl font-bold text-[#C8F95B] mt-2">
              Benefits
            </h2>
          </ScrollParallaxText>
          <div className="pl-6">
            <BulletPoint text="Improved Safety Compliance: Real-time gear detection and restricted area monitoring keep workers safe and sites compliant." />
            <BulletPoint text="Cost Reduction: Reduced accident risks mean fewer stoppages and liability concerns." />
            <BulletPoint text="Enhanced Site Security: Restricted area monitoring helps prevent unauthorized access and related incidents." />
            <BulletPoint text="Comprehensive Oversight: Safety data and insights provide clarity on compliance trends and opportunities for improvement." />
          </div>
        </div>
      </div>

      {/* Get Started Section */}
      <div className="container relative mx-auto flex flex-col px-12 mb-32 sm:mb-10 z-20">
        <ScrollParallaxText>
          <h2 className="text-xl sm:text-2xl font-bold text-[#C8F95B] mt-8">
            Get Started
          </h2>
        </ScrollParallaxText>
        <ScrollParallaxText>
          <p className="leading-normal sm:leading-[45px]">
            Enhance your site’s safety and compliance with Safety AI.{" "}
            <Link
              to="/contact"
              onClick={scrollToTop}
              className="font-bold underline text-white"
            >
              Contact us
            </Link>
            today to see how we can help you build a safer work environment.
          </p>
        </ScrollParallaxText>
      </div>

      <Footer />
    </>
  );
};

export default SafetyMonitoring;
