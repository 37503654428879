import React from "react";
import Container from "./Container";

const Partners = () => {
  return (
    <Container>
      <div className="flex items-center justify-evenly mb-5">
        <img className="w-16 md:w-32" src="/partners-one.png" alt="" />
        <img className="w-16 md:w-32" src="/partners-two.png" alt="" />
        <img className="w-16 md:w-32" src="/partners-three.png" alt="" />
      </div>
    </Container>
  );
};

export default Partners;
