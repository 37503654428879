import { useState, useEffect } from "react";
import Container from "./Container";

const questions = [
  {
    title: "AI-Driven Safety and Efficiency",
    description:
      "At VisionBuild, we harness the power of artificial intelligence to revolutionize construction site management. Our AI-driven solutions ensure safety, efficiency, and compliance, making your construction projects safer and smarter.",
    image: "/ai-driven-safety.jpeg",
  },
  {
    title: "Comprehensive Monitoring",
    description:
      "Our system uses existing security cameras to provide real-time detection of safety gear usage.Our platform sends immediate alerts to the concerned personnel in case of any violations.",
    image: "/comprehensive-monitoring.jpeg",
  },
  {
    title: "Immediate Alerts",
    description:
      "In the event of safety hazards or protocol breaches, our system sends immediate alerts via our web-based platform and iOS application. This ensures rapid response and strict adherence to safety standards.",
    image: "/alert.jpg",
  },
  {
    title: "Data-Driven Insights",
    description:
      "Our platform offers detailed reports and analytics on safety compliance, helping construction managers identify trends and areas for improvement. This data-driven approach ensures continuous enhancement of safety measures.",
    image: "/data-driven.png",
  },
];

function AnimatedMeshGradient() {
  return (
    <div className="absoluteAnimation inset-0 justify-center">
      <div className="bg-shape1 bg-primary-blue opacity-50 bg-blur"></div>
      <div className="bg-shape2 bg-[#C8F95B] opacity-50 bg-blur"></div>
    </div>
  );
}

const Question = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  // Bir sonraki soruya otomatik geçiş ve animasyon
  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 100) {
        setProgress(progress + 1);
      } else {
        setProgress(0);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % questions.length);
      }
    }, 200);

    return () => clearInterval(interval);
  }, [progress]);

  // Sorulara tıklanınca zamanlayıcı sıfırlanacak
  const handleQuestionClick = (index) => {
    setCurrentIndex(index);
    setProgress(0); // Animasyonu sıfırla
  };

  return (
    <Container>
      <div className="flex flex-col-reverse md:flex-row justify-between items-center py-8 mt-40 md:mt-5 h-screen relative z-10">
        <AnimatedMeshGradient />
        <div className="w-full md:w-1/2">
          {questions.map((question, index) => (
            <div
              key={index}
              className={`p-4 relative cursor-pointer ${
                index === currentIndex ? "text-white" : "text-purple-400"
              }`}
              onClick={() => handleQuestionClick(index)}
            >
              <h2
                className={`text-xl mb-5 font-bold ${
                  index === currentIndex ? "text-[#C8F95B]" : "text-[#7660F9]"
                }`}
              >
                {question.title}
              </h2>
              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  index === currentIndex
                    ? "max-h-[200px] opacity-100"
                    : "max-h-0 opacity-0"
                }`}
              >
                {index === currentIndex && (
                  <p className="mb-6 text-lg">{question.description}</p>
                )}
              </div>
              <div className="w-full h-1 bg-[#777777] mt-2 relative rounded-full">
                <div
                  className="h-full bg-[#C8F95B] transition-all duration-500 ease-out rounded-full"
                  style={{ width: `${index === currentIndex ? progress : 0}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full md:w-1/2 flex justify-center items-center">
          <div className="relative w-full md:w-[500px] h-[300px] md:h-[450px] rounded-lg overflow-hidden border border-[#C8F95B]">
            <img
              src={questions[currentIndex].image}
              alt={questions[currentIndex].title}
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Question;
