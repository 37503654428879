import React, { useState } from "react";
import Container from "./Container";
import SplineBanner from "./SplineBanner";
import BlurryCursor from "./BlurryCursor";

const Banner = () => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Container>
      <div className="flex flex-col-reverse justify-center items-center h-screen md:flex-row md:justify-center md:items-center relative z-10">
        <div className="w-1/2 flex flex-col justify-center items-start pl-0 px-5 text-primary text-[45px] leading-[70px] md:text-[120px] md:leading-[126px] font-extrabold tracking-[0.03em]">
          <p
            onMouseOver={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}
          >
            Build
          </p>
          <p
            onMouseOver={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}
          >
            Safer,
          </p>
          <p
            onMouseOver={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}
          >
            Faster,
          </p>
          <p
            onMouseOver={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}
          >
            Smarter.
          </p>
        </div>

        <div className="w-1/2">
          <SplineBanner />
        </div>
      </div>
      <BlurryCursor isActive={isActive} />
    </Container>
  );
};

export default Banner;
